<template>
  <div role="main" class="main">
    <section class="">
      <div class="registration-page-content common-page">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-4 text-left page-heading">
              <h3 class="m-0">Upload Your Photos</h3>
            </div>
            <div class="col-12 col-md-5 col-xl-6">
              <div class="upload-story-card">
                <div class="d-flex align-items-center profile-avatar">
                  <img :src="visitor.profile_url" class="img-fluid"/>
                </div>
                
                <div class="mt-2 mb-2">
                    <p class="m-0 text-black text-left">{{message}}</p>
                </div>
                <div class="upload-img">
                    <img :src="photo_url" class="img-fluid w-100 ">
                </div>
              </div>
            </div>
            <div class="col-12 col-md-3 "></div>
          </div>
        </div>
      </div>
      <div class="registration-page-footer">
        <div class="footer-wrapper container-fluid">
          <div class="row">
            <div class="col-lg-3"></div>
            <div class="col-lg-3"></div>
            <div class="col-lg-3">
              <div class="img-avatar-banner">
                <img
                  src="../../assets/img/upload-photo.png"
                  class="img-fluid banner-avatar round-image"
                />
                <div class="banner-name" @click="uploadPhoto()">
                  Upload a photo
                </div>
                
              </div>
            </div>
            <div class="col-lg-3">
              <div class="img-avatar-banner">
                <img
                  src="../../assets/img/DonateWithPaypal.png"
                  class="img-fluid banner-avatar round-image"
                />
                <div class="banner-name" @click="returnProgram()">
                  Return to Program
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { getApiManager } from "../../api";
import { apiBaseUrl } from "../../constants/config";

export default {
  data() {
    return {
      photo_id: 0,
      photo_url:'',
       visitor: {
        full_name: "",
        profile_url: null,
      },
    };
  },
  mounted(){
    console.log("pppppp[[[[[[",this.$route.params.p_id)
   this.photo_id = this.$route.params.p_id;
   this.event_id = this.$route.params.e_id;
   getApiManager()
      .post(`${apiBaseUrl}/api/visitor/photo/get-info`, {
        photo_id: this.photo_id,
      })
      .then((response) => {
        let res = response.data;
        if (res.status === true) {
          this.message = res.detail.message;
          console.log("1111111111111111111",res.detail);
          this.visitor.full_name = res.detail.full_name;
          this.visitor.profile_url=`${apiBaseUrl}/` + res.detail.visitor_profile_url;
          this.photo_url = `${apiBaseUrl}/` + res.detail.picture_url;
          console.log("ooooooooooo",this.visitor.profile_url)
        } else {
          this.$notify({
            group: "foo",
            type: "warn",
            title: "Error",
            text: "Server Error",
            animation_type: "slide",
          });
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    returnProgram() {
      let randomnumber = Math.floor(Math.random() * 10000) + 1;
      this.$router.push("/visitor/dashboard/"+this.event_id+"?x=" + randomnumber);
    },
    uploadPhoto() {
      let randomnumber = Math.floor(Math.random() * 10000) + 1;
      this.$router.push("/visitor/upload_photo?x=" + randomnumber);
    },
  },
  computed: {},
  watch: {},
};
</script>
<style>
@font-face {
  font-family: "Fontin-Regular";
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/Fontin-Regular.woff") format("woff");
}
@font-face {
  font-family: "Fontin-Bold";
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/Fontin-Bold.woff") format("woff");
}
.registration-page-content {
  height: calc(100vh - 135px);
  padding-top: 9rem;
  padding-bottom: 4rem;
  background-image: url("../../assets/img/back-img.png") !important;
  background-size: contain;
}
.registration-page-footer {
  position: relative;
  background-image: url("../../assets/img/footer-img.png");
  background-repeat: repeat-y;
  background-position: left top;
  background-size: contain;
  padding: 15px 0;
  border-image-slice: 1;
  border-top: 5px solid;
  border-image-source: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(119, 40, 3, 1) 0%,
    rgba(163, 62, 11, 1) 15%,
    rgba(195, 85, 12, 1) 33%,
    rgba(102, 29, 3, 1) 59%,
    rgba(102, 21, 3, 1) 64%,
    rgba(102, 29, 3, 1) 76%,
    rgba(157, 57, 6, 1) 88%
  );
}
.registration-page-footer::before {
  content: "";
  height: 100%;
  width: 100%;
  background: #000000b0;
  position: absolute;
  top: 0;
  left: 0;
}
.page-heading h3 {
  color: #fff;
  font-family: "Fontin-Regular";
}
.arrow-icon-wrapper {
  height: 24px;
  width: 24px;
}
.avatar-img img {
  height: 150px;
  width: 150px;
  border: 3px solid #fff;
}
.white-border {
  border: 1px solid #fff;
  color: #fff;
}
.img-avatar-banner {
  display: flex;
  align-items: center;
}
.banner-avatar {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border: 2px solid #fff;
  position: relative;
  z-index: 2;
}
.banner-name {
  color: #fff;
  font-weight: 400;
  border: 1px solid #fff;
  background: rgba(0, 0, 0, 0.7);
  padding: 8px 30px;
  position: relative;
  margin-left: -23px;
  z-index: 1;
  cursor: pointer;
}
.banner-name span {
  font-weight: 900;
}
.footer-wrapper {
  /* padding: 0 12vw; */
}
.c-column-flex {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.upload-story-card {
  background: #fff;
  padding: 15px;
}
.upload-img img{
  /* height: 280px; */
  height: 100px !important;
  width: 100px !important;
    /* object-fit: cover;
    min-width: 100%; */
}
.profile-avatar img{
  height: 50px;
  width: 50px;
  object-fit: cover;
}
</style>
